import { Trans, useTranslation } from 'react-i18next';

import { CheckboxField } from '~/components/form';

export const StaySignedIn = ({ className }: { className?: string }) => {
  const { t } = useTranslation('field');
  return (
    <CheckboxField
      className={className}
      name='persistent'
      label={
        <Trans ns='field' i18nKey='stay-signed-id' t={t}>
          Stay signed in
        </Trans>
      }
    />
  );
};
