import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/libs/utils';

const spacerVariants = cva('flex', {
  variants: {
    size: {
      sm: 'p-3',
      md: 'p-4',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface SpacerProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof spacerVariants> {}

const Spacer = React.forwardRef<HTMLDivElement, SpacerProps>(({ className, size, ...props }, ref) => (
  <div className={cn(spacerVariants({ size, className }))} ref={ref} {...props} />
));
Spacer.displayName = 'Spacer';

export { Spacer, spacerVariants };
