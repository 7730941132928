import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useGlobalLoadingState } from 'remix-utils/use-global-navigation-state';

import { ApplicationIcon } from '~/components/application-icons';
import { Button } from '~/components/ui/button';
import { Spacer } from '~/components/ui/spacer';

const ns = 'login' satisfies Ns;

interface LoginGoogleProps {
  className?: string;
}

const LoginGoogle = ({ className }: Readonly<LoginGoogleProps>) => {
  const location = useLocation();
  const state = useGlobalLoadingState();
  const { t } = useTranslation(ns);

  return (
    <Button
      className={className}
      variant='outline'
      icon={<ApplicationIcon icon='google' />}
      isLoading={state === 'loading'}
    >
      <a href={`/oauth/Google?returnUrl=${location.pathname}`}>
        <Trans ns={ns} i18nKey='button.google' t={t}>
          Google
        </Trans>
      </a>
    </Button>
  );
};

interface LoginMicrosoftProps {
  className?: string;
}

const LoginMicrosoft = ({ className }: Readonly<LoginMicrosoftProps>) => {
  const location = useLocation();
  const state = useGlobalLoadingState();
  const { t } = useTranslation(ns);

  return (
    <Button
      className={className}
      variant='outline'
      icon={<ApplicationIcon icon='microsoft' />}
      isLoading={state === 'loading'}
    >
      <a data-testid='login-microsoft' href={`/oauth/Microsoft?returnUrl=http://localhost:3000${location.pathname}`}>
        <Trans ns={ns} i18nKey='button.microsoft' t={t}>
          Microsoft
        </Trans>
      </a>
    </Button>
  );
};

export interface ExternalLoginProvidersProps {
  authenticationMethods: string[];
}

export const ExternalLoginProviders = ({ authenticationMethods }: Readonly<ExternalLoginProvidersProps>) => {
  const hasMicrosoft = authenticationMethods.includes('Microsoft');
  const hasGoogle = authenticationMethods.includes('Google');
  const { t } = useTranslation(ns);

  const any = hasMicrosoft || hasGoogle;
  const size = 'sm';

  return any ? (
    <>
      <Spacer size={size} />

      <div className='grid grid-flow-col grid-cols-3 gap-3'>
        <hr className='m-auto w-full' />
        <span className='text-center'>
          <Trans ns={ns} i18nKey='alternate-logins' t={t}>
            or login with
          </Trans>
        </span>
        <hr className='m-auto w-full' />
      </div>

      <Spacer size={size} />

      <div className='grid grid-flow-col grid-cols-3 gap-3'>
        {hasMicrosoft ? <LoginMicrosoft className='only:col-start-2' /> : null}
        {hasGoogle ? <LoginGoogle className='only:col-start-2' /> : null}
      </div>
    </>
  ) : null;
};
